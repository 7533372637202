import React, { useMemo } from "react";
import Button from "../../../atoms/Button/Button";
import styles from "./StudentCompetenceListTable.module.scss";
import StatisticBar from "../../../atoms/table/StatisticBar/StatisticBar";
import Level from "../../../atoms/Level/Level";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../molecules/ProgressBar/ProgressBar";
import { languages } from "../../../../dummyData/dummyData";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import Table from "../Table";

function StudentCompetenceListTable({ reports }) {
  const history = useHistory();

  const items = reports.map((item) => ({
    visibility: languages.EN.enums.visibility[item.internal === true ? 2 : 3],
    category: item.video === false ? languages.EN.labels.pdf : languages.EN.labels.video,
    status: item.verified === false ? languages.EN.labels.notVerified : languages.EN.enums.verificationTypes[item.verification_type],
    ...item,
  }));

  const maxTestDiff = Math.max(...items.map((item) => Math.abs(item.test_diff * 100)));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.name,
      accessor: "name",
    },
    {
      Header: languages.EN.labels.visibility,
      accessor: "visibility",
    },
    {
      Header: languages.EN.labels.currentLevel,
      accessor: "level",
      Cell: (data) => <Level level={data.cell.value} />,
    },
    {
      Header: languages.EN.labels.goalLevel,
      accessor: "goal_level",
      Cell: (data) => <Level level={data.cell.value} />,
    },
    {
      Header: languages.EN.labels.status,
      accessor: "status",
    },
    {
      Header: languages.EN.labels.testAttemps,
      accessor: "num_test_attempts",
    },
    {
      Header: languages.EN.labels.averageTestResult,
      accessor: "avg_test_score",
      sortType: "basic",
      Cell: (data) => data.row.original.num_test_attempts > 0 ? <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.cell.value * 100} /> : <hr/>,
    },
    {
      Header: languages.EN.labels.testResoultsComparedToOrtger,
      accessor: "test_diff",
      sortType: "basic",
      Cell: (data) => data.row.original.num_test_attempts > 0 ? <StatisticBar max={maxTestDiff} value={data.cell.value * 100} /> : <hr/>,
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onClick={() => history.push(generatePath(Routes.competenceDetails.jobPositions.base, { id: data.row.original.competence_id }))}
            onlyIcon
            icon="dots"
            tooltip={languages.EN.labels.details}
          />
        </div>
      ),
    },
  ], []);

  return <Table columns={columns} data={items} />;
}

export default StudentCompetenceListTable;
