import styles from "./InfoPage.module.scss";
import Text from "../../atoms/Text/Text";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import React, { useState } from "react";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import Button from "../../atoms/Button/Button";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import Html from "../../atoms/Html/Html";
import PreviewPlayer from "../PreviewPlayer/PreviewPlayer";
import { languages } from "../../../dummyData/dummyData";
import playVideo2 from "../../../assets/icons/playVideo2.svg";

const InfoPage = ({ image, preview, inactive, columnHeader, columnHeaderButtons, columnItems, columnFooter, children, mainFooter }) => {
  const [isPreviewClicked, setIsPreviewClicked] = useState(false);

  return (
    <div className={styles.infoPage}>
      <div className={styles.infoPage__leftColumn}>
        {image && <div className={`${styles.infoPage__leftColumn__image} ${inactive ? styles.inactive : ""}`}>
          <img src={image} alt="" />
          {inactive && <div className={styles.infoPage__leftColumn__imgInactiveCover}>
            <Text s32 stroke w800>
              {languages.EN.labels.inactive.toUpperCase()}
            </Text>
          </div>}
          {preview ? (
            <div className={styles.infoPage__leftColumn__previewIcon}>
              <img src={playVideo2} alt="" onClick={() => setIsPreviewClicked(true)} />
              <Text s12 w800 lh23 light>
                {languages.EN.labels.preview.toUpperCase()}
              </Text>
            </div>
          ) : null}
        </div>}
        <div className={styles.infoPage__leftColumn__header}>
          <Text s20 lh32 w800 secondary>
            {columnHeader}
          </Text>
          {columnHeaderButtons && columnHeaderButtons.map((button) => (
            <Button
              variant={button.statusVariant === undefined ? "iconButton" : (button.statusVariant ? "iconButtonOn" : "iconButtonOff")}
              onlyIcon
              icon={button.icon}
              key={button.icon}
              onClick={button.onClick}
              tooltip={button.tooltip}
            />
          ))}
        </div>
        <div className={styles.infoPage__leftColumn__informations}>
          {columnItems.map((item, idx) => item.image !== undefined ? (
            <TileItemImgInfo header={item.header} value={item.value} key={idx} image={item.image} />
          ) : (
            <TileItemInfo header={item.header} value={item.value} key={idx} />
          ))}
        </div>
        {columnFooter && (
          <div className={styles.infoPage__leftColumn__footer}>
            {columnFooter.map((columnFooterItem, i) => (
              <div className={styles.infoPage__leftColumn__footer__item} key={i}>
                <div className={styles.infoPage__leftColumn__footer__item__header}>
                  <Text s12 lh14 w600 secondary>
                    {columnFooterItem.header}
                  </Text>
                </div>
                <div className={styles.infoPage__leftColumn__footer__item__content}>
                  <RoundImage image={columnFooterItem.image} size={50} />
                  <Html s12 lh20 dark-6>
                    {columnFooterItem.value}
                  </Html>
                  {columnFooterItem.buttons && (
                    <div className={styles.infoPage__leftColumn__footer__item__content__buttons}>
                      {columnFooterItem.buttons}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.infoPage__mainContent}>
        {children}
        {mainFooter && (
          <div className={styles.infoPage__mainContent__footer}>
            {mainFooter.map((contentFooterItem, i) => (
              <div className={styles.infoPage__mainContent__footer__item} key={i}>
                <Text s20 w800 secondary>
                  {contentFooterItem.header}
                </Text>
                <ul>{contentFooterItem.list.map((listItem, j) => (
                  <li key={j}>
                    <div>{listItem}</div>
                  </li>
                ))}</ul>
              </div>
            ))}
          </div>
        )}
      </div>
      {preview && isPreviewClicked && <PreviewPlayer url={preview} onClose={() => setIsPreviewClicked(false)} />}
    </div>
  )
};

export default InfoPage;
