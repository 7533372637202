import {
  course_assign_endpoint,
  course_audit_endpoint,
  course_content_endpoint,
  course_content_pdf_endpoint,
  course_edit_endpoint,
  course_material_pdf_endpoint,
  course_review_endpoint,
  courseDetails,
  courseEnroll,
  courseMaterial,
  courses_endpoint,
  course_share_endpoint,
  user_material_statistics_endpoint,
  user_pdf_statistics_endpoint,
  user_video_statistics_endpoint,
  course_likes_endpoint,
  courses_list_endpoint,
} from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";
import { mimeTypes } from "./files.repository";

const getCourseDetails = (id) => {
  return fetch(courseDetails + encodeURIComponent(id) + "/", {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const downloadCourseMaterial = (id, name) => {
  const extension = name.substring(name.lastIndexOf('.') + 1);

  return fetch(courseMaterial + encodeURIComponent(id) + "/", {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.download = name;
      link.href = URL.createObjectURL(new Blob([blob], { type: mimeTypes[extension] }));
      link.click();
    });
};

const sendCourseEnroll = (id) => {
  return fetch(courseEnroll + id + "/", {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `course_id=${id}`,
  });
};


const getCourses = () => {
  return fetch(courses_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getCoursesList = () => {
  return fetch(courses_list_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getCourseContent = (id) => {
  return fetch(course_content_endpoint + id + "/", {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const getCourseContentPdf = (id) => {
  return fetch(`${course_content_pdf_endpoint}download/${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(new Blob([blob], { type: 'application/pdf' })));
};

const getCourseMaterialPdf = (id) => {
  return fetch(`${course_material_pdf_endpoint}download/${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(new Blob([blob], { type: 'application/pdf' })));

};

const getEditableCourseContent = (id) => {
  return fetch(`${course_edit_endpoint}content/${id}/`, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};


const sendUserVideoStatistics = (data) => {
  return fetch(user_video_statistics_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const sendUserPdfStatistics = (data) => {
  return fetch(user_pdf_statistics_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const sendUserMaterialStatistics = (data) => {
  return fetch(user_material_statistics_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const addCourse = (data) => {
  return fetch(`${course_edit_endpoint}course/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editCourse = (id, data) => {
  return fetch(`${course_edit_endpoint}course/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const addSection = (data) => {
  return fetch(`${course_edit_endpoint}section/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editSection = (id, data) => {
  return fetch(`${course_edit_endpoint}section/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteSection = (id) => {
  return fetch(`${course_edit_endpoint}section/${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const moveSection = (data) => {
  return fetch(`${course_edit_endpoint}section_move/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data)
  });
};

const addUnit = (data) => {
  return fetch(`${course_edit_endpoint}unit/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editUnit = (id, data) => {
  return fetch(`${course_edit_endpoint}unit/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteUnit = (id) => {
  return fetch(`${course_edit_endpoint}unit/${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const moveUnit = (data) => {
  return fetch(`${course_edit_endpoint}unit_move/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data)
  });
};


const addUnitReference = (data) => {
  return fetch(`${course_edit_endpoint}unit_document/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editUnitReference = (id, data) => {
  return fetch(`${course_edit_endpoint}unit_document/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteUnitReference = (id) => {
  return fetch(`${course_edit_endpoint}unit_document/${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addQuestion = (data) => {
  return fetch(`${course_edit_endpoint}question/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editQuestion = (id, data) => {
  return fetch(`${course_edit_endpoint}question/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteQuestion = (id) => {
  return fetch(`${course_edit_endpoint}question/${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addResource = (data) => {
  return fetch(`${course_edit_endpoint}material/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteResource = (id) => {
  return fetch(`${course_edit_endpoint}material/${id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addReview = (id, data) => {
  return fetch(`${course_review_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteReview = (id) => {
  return fetch(`${course_review_endpoint + id}/`, {
    method: "DELETE",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const addAudit = (data) => {
  return fetch(course_audit_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editAudit = (id, data) => {
  return fetch(`${course_audit_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editAuditUnits = (id, data) => {
  return fetch(`${course_audit_endpoint}units/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const copyAuditCourse = (id, data) => {
  return fetch(`${course_audit_endpoint}copy/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const finalizeAudit = (id) => {
  return fetch(`${course_audit_endpoint}finalize/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const shareCourse = (data) => {
  return fetch(course_share_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const sendLike = (data) => {
  return fetch(course_likes_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const assignToUsers = (data) => {
  return fetch(course_assign_endpoint, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

export const coursesRepository = {
  getCourseDetails,
  downloadCourseMaterial,
  sendCourseEnroll,

  getCourses,
  getCoursesList,
  getCourseContent,
  getCourseContentPdf,
  getCourseMaterialPdf,
  getEditableCourseContent,
  sendUserVideoStatistics,
  sendUserPdfStatistics,
  sendUserMaterialStatistics,
  addCourse,
  editCourse,
  addSection,
  editSection,
  deleteSection,
  moveSection,
  addUnit,
  editUnit,
  deleteUnit,
  moveUnit,
  addUnitReference,
  editUnitReference,
  deleteUnitReference,
  addQuestion,
  editQuestion,
  deleteQuestion,
  addResource,
  deleteResource,
  addReview,
  deleteReview,
  addAudit,
  editAudit,
  editAuditUnits,
  copyAuditCourse,
  finalizeAudit,
  shareCourse,
  sendLike,
  assignToUsers,
};
