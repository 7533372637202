import React, { useContext, useMemo } from "react";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { languages } from "../../../../dummyData/dummyData";
import Table, { ROW_VARIANT_ERROR, ROW_VARIANT_WARNING } from "../Table";
import { TYPE_DATE, TYPE_FILE } from "../../../molecules/Form/Form";
import Status, { VARIANT_GREEN, VARIANT_RED, VARIANT_YELLOW } from "../../../atoms/table/Status/Status";
import moment from "moment";
import styles from "../Table.module.scss";
import Button from "../../../atoms/Button/Button";
import { invoicesRepository } from "../../../../repository/invoices.repository";
import { currencyFormat } from "../../../../utils/formatters";

const popupFields = (organizationName) => [
  {
    name: "organization",
    label: languages.EN.labels.organization,
    disabled: true,
    value: organizationName,
  },
  {
    name: "number",
    label: languages.EN.labels.invoiceNumber,
    required: true,
  },
  [
    {
      name: "issue_date",
      label: languages.EN.labels.dateOfIssue,
      required: true,
      type: TYPE_DATE,
    },
    {
      name: "due_date",
      label: languages.EN.labels.deadlineDate,
      required: true,
      type: TYPE_DATE,
    },
  ],
  {
    name: "amount",
    label: languages.EN.labels.amount,
    required: true,
  },
  {
    name: "data_file",
    label: languages.EN.labels.attachFile,
    required: true,
    type: TYPE_FILE,
  },
];

const InvoicesTable = ({ reports }) => {
  const { data, organizationId, organizationName, forPlatformOwner } = reports;

  const { callPopup } = useContext(PopupsContext);

  const items = data.map((item) => ({
    ...item,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.invoiceNumber,
      accessor: "number",
    },
    {
      Header: languages.EN.labels.dateOfIssue,
      accessor: "issue_date",
    },
    {
      Header: languages.EN.labels.paymentDeadline,
      accessor: "due_date",
    },
    {
      Header: languages.EN.labels.amount,
      accessor: (data) => currencyFormat(data.amount),
    },
    {
      Header: languages.EN.labels.status,
      accessor: (data) => {
        let text, variant;

        if (data.paid) {
          text = languages.EN.labels.paid;
          variant = VARIANT_GREEN;
        } else if (moment().diff(data.due_date, "days") > 0) {
          text = `${languages.EN.labels.overdue} (${moment().diff(data.due_date, "days")} ${languages.EN.labels.days})`;
          variant = VARIANT_RED;
        } else {
          text = languages.EN.labels.unpaid;
          variant = VARIANT_YELLOW;
        }

        return forPlatformOwner ? (
          <Status
            variant={variant}
            text={text}
          />
        ) : <>{text}</>;
      },
      cellStyle: { position: "relative" },
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="download"
            onClick={() => invoicesRepository.downloadInvoice(data.row.original.id, data.row.original.number)}
            tooltip={languages.EN.labels.download}
          />
          {forPlatformOwner && (
            <Button
              variant="iconButton"
              onlyIcon
              icon={data.row.original.paid ? "cancel" : "accept"}
              onClick={() => callPopup({
                type: ACTIONS.POST_CONFIRM,
                payload: {
                  header: languages.EN.labels.confirmation,
                  content: data.row.original.paid ? languages.EN.messages.markUnpaid : languages.EN.messages.markPaid,
                  action: () => invoicesRepository.markPaidInvoice(data.row.original.id),
                },
              })}
              tooltip={languages.EN.labels[data.row.original.paid ? "markAsUnpaid" : "markAsPaid"]}
            />
          )}
        </div>
      ),
    },
  ], []);

  return <Table buttons={forPlatformOwner ? [
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.invoice}`,
          fields: popupFields(organizationName),
          postAction: (formData) => invoicesRepository.addNewInvoice({ ...formData, organization: organizationId }),
        },
      }),
    },
  ] : []} columns={columns} data={items} searchBar={false} getRowProps={(row) => {
    const overdue = moment().diff(row.original.due_date, "days");

    return {
      [ROW_VARIANT_ERROR]: !forPlatformOwner && !row.original.paid && overdue > 0,
      [ROW_VARIANT_WARNING]: !forPlatformOwner && !row.original.paid && overdue <= 0,
    }
  }}/>;
}

export default InvoicesTable;
