import React, { useMemo } from "react";
import { languages } from "../../../../dummyData/dummyData";
import Table from "../Table";
import Status, { VARIANT_GRAY, VARIANT_GREEN } from "../../../atoms/table/Status/Status";
import RatingStars from "../../../atoms/RatingStars/RatingStars";
import styles from "../Table.module.scss";
import Button from "../../../atoms/Button/Button";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../../routes";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../../molecules/ProgressBar/ProgressBar";
import { currencyFormat, percentageFormat } from "../../../../utils/formatters";

const InstructorCoursesTable = ({ reports }) => {
  const { data } = reports;
  const history = useHistory();

  const items = data
    .filter(item => item.course.active || data.filter(subItem => subItem.course.handle === item.course.handle && subItem.course.active).length === 0)
    .map(item => {
      let num_students_prev = 0, num_finish_prev = 0, num_pass_prev = 0;

      const subRowsData = data
        .filter(subRowItem => item.historic_courses.includes(subRowItem.course.id) || item.course.id === subRowItem.course.id)
        .map(subRowItem => {
          const enrollments_count = subRowItem.num_students - num_students_prev;
          const finish_rate = enrollments_count !== 0 ? (subRowItem.num_finish - num_finish_prev) / enrollments_count : undefined;
          const pass_rate = subRowItem.num_students !== 0 ? subRowItem.num_pass / subRowItem.num_students : undefined;
          num_students_prev = subRowItem.num_students;
          num_finish_prev = subRowItem.num_finish;
          num_pass_prev = subRowItem.num_pass;

          return {
            ...subRowItem,
            id: `${languages.EN.labels.version} ${subRowItem.course.course_version}`,
            statusText: subRowItem.course.active ? languages.EN.labels.active : languages.EN.labels.inactive,
            finish_rate: finish_rate,
            pass_rate: pass_rate,
            is_subrow: true,
          }
        })

      return {
        ...item,
        id: item.course.handle,
        statusText: item.course.active ? languages.EN.labels.active : languages.EN.labels.inactive,
        finish_rate: item.num_students !== 0 ? item.num_finish / item.num_students : undefined,
        pass_rate: item.num_students !== 0 ? item.num_pass / item.num_students : undefined,
        video_usage: subRowsData.reduce((acc, curr) => acc + curr.video_usage, 0),
        practical_usage: subRowsData.reduce((acc, curr) => acc + curr.practical_usage, 0),
        material_usage: subRowsData.reduce((acc, curr) => acc + curr.material_usage, 0),
        viewership: subRowsData.reduce((acc, curr) => acc + curr.viewership, 0),
        revenue: subRowsData.reduce((acc, curr) => acc + curr.revenue, 0),
        subRows: subRowsData,
      }
    });

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.id,
      accessor: "id",
      rowSpan: 2,
    },
    {
      Header: languages.EN.labels.name,
      accessor: "course.display_name",
      rowSpan: 2,
    },
    {
      Header: languages.EN.labels.status,
      accessor: "statusText",
      Cell: (data) => (
        <Status
          variant={data.row.original.course.active ? VARIANT_GREEN : VARIANT_GRAY}
          text={data.row.original.statusText.toUpperCase()}
        />
      ),
      rowSpan: 2,
      cellStyle: { position: "relative" },
    },
    {
      Header: languages.EN.labels.popularity,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          Header: languages.EN.labels.students,
          accessor: "num_students",
        },
        {
          Header: languages.EN.labels.rating,
          accessor: "course.course_rating",
          sortType: "basic",
          Cell: (data) => data.row.original.course.course_rating_num > 0 ? <RatingStars rating={data.value} /> : <hr/>,
        },
        {
          Header: languages.EN.labels.searchPopularity,
          accessor: "search_hits",
        },
      ],
    },
    {
      Header: languages.EN.labels.learning,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          Header: languages.EN.labels.finishPercentage,
          accessor: "finish_rate",
          sortType: "basic",
          Cell: (data) => data.value !== undefined ? <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.value * 100} /> : <hr/>,
        },
        {
          Header: languages.EN.labels.passPercentage,
          accessor: "pass_rate",
          sortType: "basic",
          Cell: (data) => data.value !== undefined ? <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={data.value * 100} /> : <hr/>,
        },
      ],
    },
    {
      Header: languages.EN.labels.minutesPlayed,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          Header: languages.EN.labels.video,
          accessor: "video_usage",
          Cell: (data) => Math.round(data.value / 60),
        },
        {
          Header: languages.EN.labels.cpa,
          accessor: "practical_usage",
          Cell: (data) => Math.round(data.value / 60),
        },
        {
          Header: languages.EN.labels.alm,
          accessor: "material_usage",
          Cell: (data) => Math.round(data.value / 60),
        },
      ],
    },
    {
      Header: languages.EN.labels.monetization,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          Header: languages.EN.labels.viewership,
          accessor: "viewership",
          sortType: "basic",
          Cell: (data) => percentageFormat(data.value),
        },
        {
          Header: languages.EN.labels.revenue,
          accessor: "revenue",
          sortType: "basic",
          Cell: (data) => currencyFormat(data.value),
        },
      ],
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center", maxWidth: "130px" },
      rowSpan: 2,
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={() => history.push(generatePath(Routes.courseDetails.statistics.base, { id: data.row.original.course.id }))}
            tooltip={languages.EN.labels.details}
          />
        </div>
      ),
    },
  ], []);

  return <Table columns={columns} data={items} expand={false} />;
};

export default InstructorCoursesTable
