import ListItemInfo from "../../molecules/ListItemInfo/ListItemInfo";
import ListItemImgInfo from "../../molecules/ListItemImgInfo/ListItemImgInfo";
import { languages } from "../../../dummyData/dummyData";
import ListItem from "../ListItem/ListItem";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { regulatoryIntelligenceRepository } from "../../../repository/regulatoryIntelligence.repository";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { popupFields } from "../../templates/RegulatoryIntelligenceTemplate/RegulatoryIntelligenceTemplate";
import React, { useContext, useEffect, useState } from "react";
import { dateFormat } from "../../../utils/formatters";
import useFetch from "../../../hooks/useFetch";
import { getUserDisplayText } from "../Table/LicensesTable/LicensesTable";
import { coursesRepository } from "../../../repository/courses.repository";
import { organizationRepository } from "../../../repository/organization.repository";
import Text from "../../atoms/Text/Text";

const selectUsersPopupFilterDefinitions = [
  {
    keyPath: ["user.profile.name", "user.profile.surname", "user.email"],
  },
  {
    keyPath: "user.profile.user_career_path.name",
    header: languages.EN.labels.careerPath,
    typeOfFilter: "checkbox",
  },
];

const PopupRenderItem = ({ item }) => {
  const user = item.user;
  const profile = user.profile;

  return (
    <Text s12 dark-4>
      {profile.name ? `${profile.name} ${profile.surname} (${user.email})` : user.email}
    </Text>
  );
}

const RegulatoryIntelligenceItem = ({ data }) => {
  const { id, profile_image_small, title, learningArea, category, languageName, likes, created, visibility, visibilityImg, user_liked, url, canEdit } = data;
  const { callPopup, setIsDataActive } = useContext(PopupsContext);
  const [userLiked, setUserLiked] = useState(user_liked);

  let history = useHistory();

  const fetchOrganizationMembersForShare = useFetch();
  useEffect(() => {
    if (fetchOrganizationMembersForShare.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.SELECT,
        payload: {
          header: languages.EN.labels.shareWith,
          items: fetchOrganizationMembersForShare.data.map(item => ({
            ...item,
            id: item.user.username,
            label: getUserDisplayText(item.user),
          })),
          RenderItem: PopupRenderItem,
          filters: selectUsersPopupFilterDefinitions,
          singleSelect: false,
          postAction: (formData) => regulatoryIntelligenceRepository.shareArticle({ article: id, users: formData }),
        },
      });
    }
  }, [fetchOrganizationMembersForShare.isReady]);

  return (
    <ListItem title={title} image={profile_image_small} buttons={[
      {
        icon: "heart",
        statusVariant: userLiked,
        onClick: () => {
          regulatoryIntelligenceRepository.sendLike(id);
          setUserLiked(!userLiked);
        },
        tooltip: languages.EN.labels[userLiked ? 'unlike' : 'like'],
      },
      {
        icon: "home",
        onClick: () => window.open(url, '_blank'),
        tooltip: languages.EN.labels.goToPage,
      },
      {
        icon: "share",
        onClick: () => {
          setIsDataActive(false);
          fetchOrganizationMembersForShare.fetchData(organizationRepository.getMyOrganizationMembers());
          callPopup({ type: ACTIONS.LOADING })
        },
        tooltip: languages.EN.labels.share,
      },
    ].concat(canEdit ? [
      {
        icon: "edit",
        onClick: () => callPopup({
          type: ACTIONS.FORM,
          payload: {
            header: `${languages.EN.labels.edit} ${languages.EN.labels.regulatoryIntelligenceArticles}`,
            fields: popupFields(data),
            postAction: (formData) => regulatoryIntelligenceRepository.editArticle(id, formData),
          },
        }),
        tooltip: languages.EN.labels.edit,
      },
      {
        icon: "garbage",
        onClick: () => callPopup({
          type: ACTIONS.DELETE,
          payload: {
            type: languages.EN.labels.regulatoryIntelligenceArticle,
            elementName: title,
            action: () => regulatoryIntelligenceRepository.deleteArticle(id),
          },
        }),
        tooltip: languages.EN.labels.delete,
      },
    ] : []).concat([
      {
        icon: "dots",
        onClick: () => history.push(generatePath(Routes.regulatoryIntelligenceDetails.base, { id: id })),
        tooltip: languages.EN.labels.details,
      },
    ])}>
      <ListItemInfo value={learningArea} />
      <ListItemInfo value={category} />
      <ListItemInfo value={languageName} />
      <ListItemInfo value={likes} />
      <ListItemInfo value={dateFormat(created)} />
      <ListItemImgInfo value={visibility} image={visibilityImg} />
    </ListItem>
  );
};

export default RegulatoryIntelligenceItem;
