import React, { useContext, useMemo } from "react";
import { languages } from "../../../../dummyData/dummyData";
import Table from "../Table";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { TYPE_FILE } from "../../../molecules/Form/Form";
import { licenseControlRepository } from "../../../../repository/licenseControl.repository";
import styles from "../Table.module.scss";
import Button from "../../../atoms/Button/Button";
import { organizationLicensesRepository } from "../../../../repository/organizationLicenses.repository";
import { Routes } from "../../../../routes";
import { generatePath, useHistory } from "react-router-dom";
import Status, { VARIANT_GREEN, VARIANT_RED } from "../../../atoms/table/Status/Status";
import OrganizationSubRow from "./OrganizationSubRow";
import { currencyFormat } from "../../../../utils/formatters";

const popupFields = (data = {}) => [
  {
    name: "name",
    label: languages.EN.labels.name,
    required: true,
    value: data.name,
  },
  {
    name: "contact_email",
    label: languages.EN.labels.email,
    required: true,
    value: data.contact_email,
  },
  {
    name: "street",
    label: languages.EN.labels.street,
    required: true,
    value: data.street,
  },
  [
    {
      name: "postcode",
      label: languages.EN.labels.postalCode,
      required: true,
      value: data.postcode,
    },
    {
      name: "city",
      label: languages.EN.labels.city,
      required: true,
      value: data.city,
    },
  ],
  {
    name: "country",
    label: languages.EN.labels.country,
    required: true,
    value: data.country,
  },
  {
    name: "contact_phone",
    label: languages.EN.labels.phoneNumber,
    value: data.contact_phone,
  },
  {
    name: "contract_file",
    label: languages.EN.labels.agreement,
    type: TYPE_FILE,
    value: data.contract_file,
  },
];

const OrganizationsTable = ({ reports }) => {
  const { data, collections, courses } = reports;

  const { callPopup } = useContext(PopupsContext);
  const history = useHistory();

  const items = data.map((item) => ({
    ...item,
    availableTokens: item.prepaids.reduce((acc, curr) => acc + curr.available_coins, 0),
    totalTokens: item.prepaids.reduce((acc, curr) => acc + curr.total_coins, 0),
    subRow: (
      <OrganizationSubRow
        data={item}
        collections={collections}
        courses={courses}
      />
    )
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.company,
      accessor: "name",
      rowSpan: 2,
    },
    {
      Header: languages.EN.labels.noOfEmployees,
      accessor: "num_members",
      rowSpan: 2,
    },
    {
      Header: languages.EN.labels.tokens,
      accessor: "availableTokens",
      Cell: (data) => `${data.row.original.availableTokens}/${data.row.original.totalTokens}`,
      rowSpan: 2,
      cellStyle: { width: "140px" },
    },
    {
      Header: languages.EN.labels.licenses,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          Header: languages.EN.labels.amount,
          accessor: "num_licences",
          cellStyle: { width: "140px" },
        },
        {
          Header: languages.EN.labels.assigned,
          accessor: "num_licences_assigned",
          cellStyle: { width: "140px" },
        },
        {
          Header: languages.EN.labels.averagePrice,
          accessor: (data) => currencyFormat(data.avg_license_price),
          cellStyle: { width: "140px" },
        },
        {
          Header: languages.EN.labels.income,
          accessor: (data) => currencyFormat(data.total_income),
          cellStyle: { width: "140px" },
        },
      ],
    },
    {
      Header: languages.EN.labels.paymentStatus,
      accessor: (data) => (
        <Status
          variant={data.payment_ok ? VARIANT_GREEN : VARIANT_RED}
          text={data.payment_ok ? languages.EN.labels.upToDate : languages.EN.labels.overdue}
        />
      ),
      rowSpan: 2,
      cellStyle: { position: "relative" },
    },
    {
      Header: languages.EN.labels.actions,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      rowSpan: 2,
      Cell: (data) => (
        <div className={styles.actionBox}>
          <Button
            variant="iconButton"
            onlyIcon
            icon="file"
            onClick={() => organizationLicensesRepository.downloadOrganizationContract(data.row.original.id, data.row.original.name)}
            tooltip={languages.EN.labels.agreement}
          />
          <Button
            variant="iconButton"
            onlyIcon
            icon="dots"
            onClick={() => history.push(generatePath(Routes.organization.statistics.base, { id: data.row.original.id }))}
            tooltip={languages.EN.labels.details}
          />
          <Button
            variant="iconButton"
            onlyIcon
            icon="edit"
            onClick={() => callPopup({
              type: ACTIONS.FORM,
              payload: {
                header: `${languages.EN.labels.edit} ${languages.EN.labels.organization}`,
                fields: popupFields(data.row.original),
                postAction: (formData) => licenseControlRepository.editOrganization(data.row.original.id, formData),
              },
            })}
            tooltip={languages.EN.labels.edit}
          />
        </div>
      ),
      cellStyle: { maxWidth: "130px" },
    },
  ], []);


  return <Table buttons={[
    {
      icon: "plus",
      label: languages.EN.labels.add,
      onClick: () => {
        callPopup({
          type: ACTIONS.FORM,
          payload: {
            header: `${languages.EN.labels.add} ${languages.EN.labels.organization}`,
            fields: popupFields(),
            postAction: licenseControlRepository.addOrganization,
          },
        })
      },
    },
  ]} columns={columns} data={items} expand={false} />;
};

export default OrganizationsTable;
