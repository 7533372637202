import VerificationBar, { VARIANT_PRIMARY } from "../../molecules/VerificationBar/VerificationBar";
import TileItemInfo from "../../molecules/TileItemInfo/TileItemInfo";
import { languages } from "../../../dummyData/dummyData";
import TileItem from "../TileItem/TileItem";
import TileItemImgInfo from "../../molecules/TileItemImgInfo/TileItemImgInfo";
import Level from "../../atoms/Level/Level";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import { jobPositionsRepository } from "../../../repository/jobPositions.repository";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { useContext } from "react";
import { VARIANT_RED } from "../../atoms/table/Status/Status";
import { certificatesRepository } from "../../../repository/certificates.repository";
import { UserContext } from "../../../contexts/UserContext";
import { SCOPE_LEARNING } from "../../../constants/scopes";

const RelatedCompetenceAndCourseTile = ({ data, withJobPosition }) => {
  const { competence, level, job_position, visibility, visibilityImg, course, instructors, user_progress, user_verified, user_verification_type, job_competence_id, can_delete } = data;
  const { callPopup } = useContext(PopupsContext);
  const { userData } = useContext(UserContext);
  let history = useHistory();

  return (
    <TileItem
      title={competence.name}
      subtitle={`${languages.EN.labels.region}: ${competence.country}`}
      image={competence.profile_image_small}
      bar={user_verified || user_progress > 0 ? (
        <VerificationBar
          progress={user_progress}
          text={user_verification_type !== null ? languages.EN.enums.verificationTypes[user_verification_type] : languages.EN.labels.notVerified}
          variant={user_verification_type !== null ? VARIANT_PRIMARY : VARIANT_RED}
          inTile
        />
      ) : undefined}
      buttons={(userData.scopes.includes(SCOPE_LEARNING) && !userData.is_freemium && user_verification_type === 0 ? [
        {
          icon: "download",
          onClick: () => certificatesRepository.downloadCertificate(userData.username, course.id),
          tooltip: `${languages.EN.labels.download} ${languages.EN.labels.certificate}`,
        },
      ] : []).concat(can_delete ? [
        {
          icon: "garbage",
          onClick: () => callPopup({
            type: ACTIONS.DELETE,
            payload: {
              type: languages.EN.labels.jobPositionCompetence,
              elementName: `${competence.name} (${level})`,
              action: () => jobPositionsRepository.deleteJobPositionCompetence(job_competence_id),
            },
          }),
          tooltip: languages.EN.labels.delete,
        },
      ] : []).concat([
        {
          icon: "dots",
          onClick: () => history.push(generatePath(Routes.competenceDetails.jobPositions.base, { id: competence.id })),
          tooltip: languages.EN.labels.details,
        },
      ])}>
      <TileItemInfo header={languages.EN.labels.level} value={
        <Level small level={level} />
      } />
      <TileItemInfo header={languages.EN.labels.group} value={competence.group.name} />
      {withJobPosition && <TileItemInfo header={languages.EN.labels.jobPosition} value={job_position.name} />}
      <TileItemImgInfo header={languages.EN.labels.visibility} value={visibility} image={visibilityImg} />
      <TileItemInfo header={languages.EN.labels.course} value={course.name} />
      <TileItemInfo header={languages.EN.labels.duration} value={course.duration !== undefined ? `${course.duration} ${languages.EN.labels.minutes}` : undefined} />
      {instructors.map((instructor, i) => (
        <TileItemImgInfo key={i} header={languages.EN.labels.instructor} value={instructor.name} image={instructor.img} />
      ))}
    </TileItem>
  );
};
export default RelatedCompetenceAndCourseTile;
