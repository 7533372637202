import styles from "./NotificationTableRow.module.scss";
import TableCell from "../../../atoms/table/TableCell/TableCell";
import CheckBoxOnly from "../../../atoms/CheckBoxOnly/CheckBoxOnly";
import { useState } from "react";

const NotificationTableRow = ({ row, onChange }) => {
  const { description, value } = row;

  // mobile, email, web
  const [checkboxData, setCheckboxData] = useState(value.toString(2).slice(-3).padStart(3, "0").split("").map(value => parseInt(value)));

  const handleCheckboxChange = (i) => {
    let newCheckboxData = [...checkboxData];
    newCheckboxData[i] = newCheckboxData[i] ? 0 : 1;
    setCheckboxData(newCheckboxData);
    onChange(parseInt(newCheckboxData.join(""), 2));
  };

  return (
    <div className={styles.Wrapper}>
      <TableCell variant="lightText">{description}</TableCell>
      <TableCell variant="lightCheckbox">
        <CheckBoxOnly
          checked={checkboxData[2]}
          handleCheckbox={() => handleCheckboxChange(2)}
          inNotification
        />
      </TableCell>
      {/*<TableCell variant="lightCheckbox">*/}
      {/*  <CheckBoxOnly*/}
      {/*    checked={checkboxData[0]}*/}
      {/*    handleCheckbox={() => handleCheckboxChange(0)}*/}
      {/*    inNotification*/}
      {/*  />*/}
      {/*</TableCell>*/}
      <TableCell variant="lightCheckbox">
        <CheckBoxOnly
          checked={checkboxData[1]}
          handleCheckbox={() => handleCheckboxChange(1)}
          inNotification
        />
      </TableCell>
    </div>
  );
};

export default NotificationTableRow;
