import React, { useState } from "react";
import RatingStars from "../../../../atoms/RatingStars/RatingStars";
import StatisticBar from "../../../../atoms/table/StatisticBar/StatisticBar";
import CourseProgressTypeCell, { getValueFromData, TYPE_ALL, TYPE_DONE, TYPE_IN_PROGRESS, TYPE_NOT_STARTED } from "../../../../atoms/table/CourseProgressTypeCell/CourseProgressTypeCell";
import { languages } from "../../../../../dummyData/dummyData";
import { durationFormat } from "../../../../../utils/formatters";
import Table from "../../Table";
import CompoundTableHeader from "../../../../molecules/CompoundTableHeader/CompoundTableHeader";

export const COMPOUND_HEADER_CONFIG = {
  gridTemplateColumns: "1fr 1fr 1fr",
  gridTemplateAreas: `
    "${TYPE_ALL} ${TYPE_ALL} ${TYPE_ALL}" 
    "${TYPE_DONE} ${TYPE_IN_PROGRESS} ${TYPE_NOT_STARTED}"
  `,
  items: [
    {
      type: TYPE_ALL,
      label: languages.EN.labels.noOfStudentsHaving,
      headerStyle: { justifyContent: "center" },
    },
    {
      type: TYPE_DONE,
      label: languages.EN.labels.done,
      headerMarkerColor: "#78c0f5",
    },
    {
      type: TYPE_IN_PROGRESS,
      label: languages.EN.labels.inProgress,
      headerMarkerColor: "#ffdb70",
    },
    {
      type: TYPE_NOT_STARTED,
      label: languages.EN.labels.notStarted,
      headerMarkerColor: "#f27f2a",
    },
  ]
};

function CourseListTable({ reports }) {
  const { data } = reports;
  const [compoundColumnSort, setCompoundColumnSort] = useState([]);
  const items = data.map((item) => ({
    visibility: languages.EN.enums.visibility[item.internal !== false ? 2 : 3],
    category: item.video === false ? languages.EN.labels.pdf : languages.EN.labels.video,
    average_watching_time: (item.duration > 0 && item.avg_view_time > 0) ? parseFloat((((item.avg_view_time - item.duration) / item.duration) * 100).toFixed(1)) : 0,
    num_of_students: item.num_finished + item.num_in_progress + item.num_enrolled,
    ...item,
  }));
  const maxWatchingTime = Math.max(...items.map((item) => Math.abs(item.average_watching_time)));
  const maxStudents = Math.max(...items.map((item) => Math.abs(item.num_of_students)));

  const columns = React.useMemo(
    () => [
      {
        Header: languages.EN.labels.name,
        accessor: "name",
      },
      {
        Header: languages.EN.labels.visibility,
        accessor: "visibility",
      },

      {
        Header: languages.EN.labels.category,
        accessor: "category",
      },
      {
        Header: (data) => (
          <CompoundTableHeader
            config={COMPOUND_HEADER_CONFIG}
            type={compoundColumnSort}
            setType={setCompoundColumnSort}
            isSorted={data.column.isSorted}
            isSortedDesc={data.column.isSortedDesc}
            toggle={() => data.toggleSortBy("numberOfCourses")}
          />
        ),
        id: "numberOfCourses",
        accessor: (data) => getValueFromData(data, compoundColumnSort),
        Cell: (data) => (
          <CourseProgressTypeCell data={data.cell.row.original} barConfig={COMPOUND_HEADER_CONFIG.items.slice(1)} max={maxStudents} />
        ),
        disableSortBy: true,
        fullWidth: true,
      },
      {
        Header: languages.EN.labels.duration,
        accessor: "duration",
        Cell: (data) => durationFormat(data.cell.value),
      },

      {
        Header: languages.EN.labels.averageWatchingTime,
        accessor: "average_watching_time",
        sortType: "basic",
        Cell: (data) => (
          <StatisticBar inTable value={data.cell.value} max={maxWatchingTime} />
        ),
      },
      {
        Header: languages.EN.labels.totalTimeWatching,
        accessor: "total_view_time",
        Cell: (data) => durationFormat(data.cell.value),
      },
      {
        Header: languages.EN.labels.rating,
        accessor: "course_rating",
        Cell: (data) => data.cell.value > 0 ? <RatingStars rating={data.cell.value} /> : <hr/>,
      },
    ],
    [compoundColumnSort]
  );

  return <Table columns={columns} data={items} />;
}

export default CourseListTable;
