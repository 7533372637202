import React from "react";
import { languages } from "../../../../dummyData/dummyData";
import TableWrapper from "../../../molecules/TableWrapper/TableWrapper";
import TokenTranferAudit from "./TokenTranferAudit";

const TokenSubRow = ({ data }) => {
  return (
    <>
      <TableWrapper
        title={languages.EN.labels.transferAudit}
        Table={TokenTranferAudit}
        reports={{ data: data.audit }}
        transparent
      />
    </>
  );
};

export default TokenSubRow;
