import React, { useState, useEffect, useContext } from "react";
import TeamListItem from "../TeamListItem/TeamListItem";
import TeamListItemTile from "../TeamListItemTile/TeamListItemTile";
import { PopupsContext, ACTIONS } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import FilteredList from "../FilteredList/FilteredList";
import { teamRepository } from "../../../repository/team.repository";
import { TYPE_AUTOCOMPLETE, TYPE_IMAGE, TYPE_TEXTAREA } from "../../molecules/Form/Form";
import useFetch from "../../../hooks/useFetch";
import { organizationRepository } from "../../../repository/organization.repository";
import { getUserDisplayText } from "../Table/LicensesTable/LicensesTable";

const filterDefinitions = [
  {
    keyPath: "name",
    header: languages.EN.labels.name,
    sortId: 1,
  },
  {
    keyPath: "description",
  },
  {
    keyPath: "preparedManager",
    header: languages.EN.labels.manager,
    typeOfFilter: "checkbox",
    sortId: 2,
  },
  {
    keyPath: "description",
    header: languages.EN.labels.description,
    sortId: 3,
  },
  {
    keyPath: "num_members",
    header: languages.EN.labels.noOfTeamMembers,
    typeOfFilter: "range",
    sortId: 4,
  },
];

export const popupFields = (managerItems = [], data = {}, allowManagerChange = false) => [
  {
    name: "name",
    label: languages.EN.labels.name,
    required: true,
    value: data.name,
  },
  {
    name: "description",
    label: languages.EN.labels.description,
    required: true,
    type: TYPE_TEXTAREA,
    value: data.description,
  },
  {
    name: "manager",
    label: languages.EN.labels.manager,
    type: TYPE_AUTOCOMPLETE,
    required: true,
    disabled: !allowManagerChange,
    value: data.manager?.user.username,
    items: managerItems.map(item => ({
      id: item.user.username,
      label: getUserDisplayText(item.user),
    })),
  },
  {
    name: "profile_image",
    label: languages.EN.labels.image,
    type: TYPE_IMAGE,
    required: false,
    value: data.profile_image,
  },
];

const TeamsList = ({ data, withManagement }) => {
  const [preparedData, setPreparedData] = useState(null);
  useEffect(() => setPreparedData(data.map((item) => ({
    ...item,
    preparedManager: item.manager !== null ? `${item.manager.name} ${item.manager.surname}` : null,
  }))), [data]);

  const { callPopup, setIsDataActive } = useContext(PopupsContext);

  const fetchOrganizationMembers = useFetch();
  useEffect(() => {
    if (fetchOrganizationMembers.isReady) {
      setIsDataActive(true);
      callPopup({
        type: ACTIONS.FORM,
        payload: {
          header: `${languages.EN.labels.add} ${languages.EN.labels.team}`,
          fields: popupFields(fetchOrganizationMembers.data, data, true),
          postAction: teamRepository.addTeam,
        },
      })
    }
  }, [fetchOrganizationMembers.isReady]);

  return (
    <FilteredList
      id={"teams"}
      data={preparedData}
      filterDefinitions={filterDefinitions}
      RenderListComponent={TeamListItem}
      RenderTileComponent={TeamListItemTile}
      buttons={withManagement ? [
        {
          icon: "plus",
          label: languages.EN.labels.add,
          onClick: () => {
            setIsDataActive(false);
            fetchOrganizationMembers.fetchData(organizationRepository.getMyOrganizationMembers());
            callPopup({ type: ACTIONS.LOADING });
          },
        },
      ] : []}
    />
  );
};

export default TeamsList;
