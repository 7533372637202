import styles from "./LicenseControlTemplate.module.scss";
import NavBar from "../../organisms/NavBar/NavBar";
import Footer from "../../organisms/Footer/Footer";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import OrganizationsTable from "../../organisms/Table/OrganizationsTable/OrganizationsTable";
import MainTemplate from "../generic/MainTemplate/MainTemplate";
import TopBanner from "../../molecules/TopBanner/TopBanner";
import React from "react";

const LicenseControlTemplate = ({ licenses, collections, courses }) => {
  const { organizations } = licenses;
  const pageHeader = languages.EN.labels.licenseControl;

  useDocumentTitle(pageHeader);

  return (
    <section>
      <NavBar />
      <TopBanner
        header={pageHeader}
        path={[
          {
            name: pageHeader,
            link: "#",
          },
        ]}
      />
      <MainTemplate>
        <div className={styles.organizations}>
          <TableWrapper
            title={languages.EN.labels.organizations}
            Table={OrganizationsTable}
            reports={{ data: organizations, collections: collections, courses: courses }}
          />
        </div>
      </MainTemplate>
      <Footer data={languages.EN.footer} />
    </section>
  );
};

export default LicenseControlTemplate;
