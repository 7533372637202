import React, { useMemo } from "react";
import { languages } from "../../../../dummyData/dummyData";
import Table from "../Table";
import { dateFormat, dateTimeFormat } from "../../../../utils/formatters";
import { getUserDisplayText } from "../LicensesTable/LicensesTable";

const TokenTranferAudit = ({ reports }) => {
  const { data } = reports;

  const items = data.map((item) => ({
    ...item,
    employeeName: item.assigned_user === null || !item.assigned_user.profile.name ? languages.EN.placeholders.empty : `${item.assigned_user.profile.name} ${item.assigned_user.profile.surname}`,
    employeeEmail: item.assigned_user === null ? languages.EN.placeholders.empty : item.assigned_user.email,
  }));

  const columns = useMemo(() => [
    {
      Header: languages.EN.labels.createdOn,
      accessor: "created",
      Cell: (data) => dateTimeFormat(data.row.original.created),
      rowSpan: 2,
    },
    {
      Header: languages.EN.labels.createdBy,
      accessor: (data) => getUserDisplayText(data.user),
      rowSpan: 2,
    },
    {
      Header: languages.EN.labels.amount,
      accessor: "num_coins",
      rowSpan: 2,
    },
    {
      Header: languages.EN.labels.transferTarget,
      disableSortBy: true,
      headerStyle: { justifyContent: "center" },
      columns: [
        {
          Header: languages.EN.labels.licenseNumber,
          accessor: "license.number",
        },
        {
          Header: languages.EN.labels.employeeName,
          accessor: "employeeName",
        },
        {
          Header: languages.EN.labels.email,
          accessor: "employeeEmail",
        },
      ],
    },
  ], []);

  return <Table columns={columns} data={items} />;
}

export default TokenTranferAudit;
