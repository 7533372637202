import Text from "../../atoms/Text/Text";
import styles from "./ListItemImgInfo.module.scss";
import { languages } from "../../../dummyData/dummyData";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";

const ListItemImgInfo = ({ value, image }) => {
  return (
    <span className={styles.listItemImgInfo}>
      <TextTruncated lineClamp={1}>
        <Text s12 lh24 dark-6>
          {(value === undefined || value.length === 0) ? languages.EN.placeholders.empty : value}
        </Text>
      </TextTruncated>
      {image !== undefined ? (
        <RoundImage image={image} size={50} />
      ) : (
        <></>
      )}
    </span>
  );
};

export default ListItemImgInfo;
