import styles from "./SearchBackendUsersPopup.module.scss";
import React, { useContext, useEffect, useState } from "react";
import Text from "../../../atoms/Text/Text";
import SaveBackButtons from "../../SaveBackButtons/SaveBackButtons";
import useFetch from "../../../../hooks/useFetch";
import SearchBackendUsersPopupContent from "../../../atoms/SelectCheckbox/SearchBackendUsersPopupContent";
import { languages } from "../../../../dummyData/dummyData";
import PopupFilterPanel from "../../../organisms/PopupFilterPanel/PopupFilterPanel";
import { convertItemForFilter } from "../../../../hooks/convertItemForFilter";
import { organizationRepository } from "../../../../repository/organization.repository";
import { careerPathsRepository } from "../../../../repository/careerPaths.repository";
import { UserContext } from "../../../../contexts/UserContext";
import { ACTIONS, PopupsContext } from "../../../../contexts/PopupsContext";
import { PostContext } from "../../../../contexts/PostContext";

const SearchBackendUsersPopup = ({ header, singleSelect, postAction, excludeMyself, setIsDataActive }) => {
  const [searchInput, setSearchInput] = useState("");
  const [filterCareerPaths, setFilterCareerPaths] = useState([]);
  const [preparedData, setPreparedData] = useState(null);
  const [selected, setSelected] = useState([]);

  const { userData } = useContext(UserContext);
  const { callPopup } = useContext(PopupsContext);
  const { postData, isPostFinish } = useContext(PostContext);

  useEffect(() => {
    if (isPostFinish) {
      callPopup({ type: ACTIONS.REMOVE });
    }
  }, [isPostFinish]);

  // TODO: refactor to pass careerPaths and users from component parents. Popup should know nothing about repositories
  const fetchCareerPaths = useFetch();
  const fetchUsers = useFetch();

  useEffect(() => {
    fetchUsers.fetchData(organizationRepository.getPlatformUsers(searchInput, undefined, filterCareerPaths));
  }, [filterCareerPaths, fetchUsers.resetData]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchUsers.fetchData(organizationRepository.getPlatformUsers(searchInput, undefined, filterCareerPaths));
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [searchInput]);
  useEffect(() => {
    fetchCareerPaths.fetchData(careerPathsRepository.getCareerPaths());
  }, []);
  useEffect(() => {
    if (fetchUsers.isReady && fetchCareerPaths.isReady) {
      setIsDataActive(true);
    }
  }, [fetchUsers.isReady, fetchCareerPaths.isReady])

  useEffect(() => {
    if (fetchUsers.isReady) {
      const topicChoices = convertItemForFilter(languages.EN.enums.learningAreas);

      setPreparedData(
        fetchUsers.data.filter((item) => !excludeMyself || item.user.username !== userData.username).map((item) => {
          const profile = item.user.profile;

          return {
            ...item,
            label: `${profile.name ? `${profile.name} ${profile.surname}` : item.user.email} (${profile.user_job_position ? `${profile.user_job_position.name} at ` : ''}${profile.user_organization.name})`,
            id: item.user.username,
            licenseTypes: item.licenses.map((license) => languages.EN.enums.licensesTypes[license.type]),
            isChecked: false,
            selectId: item.user.username,
          };
        })
      );
    }
  }, [fetchUsers.isReady]);

  const handleCheckbox = (e) => {
    const id = e.target.value;
    if (selected.includes(id)) {
      handleDeleteSelected(id);
    } else if (singleSelect) {
      setSelected([id]);
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleDeleteSelected = (deleted) => {
    setSelected([...(selected.filter((item) => item !== deleted.toString()))]);
  };

  return (
    <>
      <Text s18 w700 lh21>
        {header}
      </Text>
      <div className={styles.wrapper}>
        <PopupFilterPanel
          setCareerPaths={setFilterCareerPaths}
          careerPaths={fetchCareerPaths.data}
        />
        {preparedData && (
          <SearchBackendUsersPopupContent
            items={preparedData}
            placeholder={languages.EN.labels.search}
            handleCheckbox={handleCheckbox}
            handleDeleteSelected={handleDeleteSelected}
            singleSelect={singleSelect}
            setInput={setSearchInput}
            selected={selected}
            isReady={fetchUsers.isReady}
          />
        )}
      </div>
      <SaveBackButtons onSave={() => postData(() => postAction(selected))} isError={selected.length <= 0} onBack={() => callPopup({ type: ACTIONS.REMOVE })} />
    </>
  );
};

export default SearchBackendUsersPopup;
