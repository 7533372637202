import React from "react";
import { languages } from "../../../../dummyData/dummyData";
import TableWrapper from "../../../molecules/TableWrapper/TableWrapper";
import InvoicesTable from "../InvoicesTable/InvoicesTable";
import LicensesTable from "../LicensesTable/LicensesTable";
import TokenTable from "../TokenTable/TokenTable";

const OrganizationSubRow = ({ data, collections, courses }) => {
  return (
    <>
      <TableWrapper
        title={languages.EN.labels.licenses}
        Table={LicensesTable}
        reports={{ data: data.licenses, organizationId: data.id, collections: collections, courses: courses, forPlatformOwner: true }}
        transparent
      />
      <TableWrapper
        title={languages.EN.labels.tokens}
        Table={TokenTable}
        reports={{ data: data.prepaids, organizationId: data.id, organizationName: data.name, forPlatformOwner: true }}
        transparent
      />
      <TableWrapper
        title={languages.EN.labels.invoices}
        Table={InvoicesTable}
        reports={{ data: data.invoices, organizationId: data.id, organizationName: data.name, forPlatformOwner: true }}
        transparent
      />
    </>
  );
};

export default OrganizationSubRow;
