import React, { useState } from "react";
import styles from "./UploadFile.module.scss";
import { languages } from "../../../dummyData/dummyData";
import Text from "../../atoms/Text/Text";
import basicImage from "../../../assets/icons/basicImg.svg";
import Label from "../../atoms/Label/Label";
import { mimeTypes } from "../../../repository/files.repository";

const UploadFile = ({
  setFormData,
  formData,
  userImg,
  isFile,
  placeholder,
  required,
  error,
  errorMessage,
  name,
  disabled,
  noMargin,
  square,
  allowedTypes
}) => {
  const [imageDescription, setImageDescription] = useState(languages.EN.messages.notSelectedFiles);
  const [img, setImg] = useState(userImg ? userImg : "");

  const allTypes = isFile
    ? [mimeTypes.pdf, mimeTypes.doc, mimeTypes.docx, mimeTypes.txt, mimeTypes.jpg, mimeTypes.png, mimeTypes.xls, mimeTypes.xlsx, mimeTypes.ppt, mimeTypes.pptx]
    : [mimeTypes.png, mimeTypes.jpg]

  const handlerImg = (e) => {
    if (e.target.files) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setImageDescription(e.target.files[0].name);
      formData &&
        setFormData(prevState => ({
          ...prevState,
          [name]: e.target.files[0],
        }));
    }
  };

  return (
    <div className={`
      ${error ? styles.Wrapper__error : ''} 
      ${isFile ? styles.fileWrapper : styles.Wrapper}
      ${noMargin ? (isFile ? styles.fileWrapper__noMargin : styles.Wrapper__noMargin) : ''}
    `}>
      <Label isFocus={true} placeholder={placeholder} error={error} required={required} absolute />
      {!isFile && (
        <div className={`${styles.Image} ${square ? styles.square : ''}`}>
          <img src={img ? img : basicImage} alt='uploaded img' />
        </div>
      )}

      <div name={name}>
        <label className={styles.photo} htmlFor={name} data-disabled={disabled}>
          {languages.EN.labels.browse}
          <>
            <input
              className={styles.uploadInput}
              type='file'
              id={name}
              accept={allTypes.filter(type => allowedTypes === undefined || allowedTypes.includes(type)).join(', ')}
              onChange={handlerImg}
              disabled={disabled}
            />
          </>
        </label>
        <Text s10 dark-8 w600>
          {imageDescription.length > 30
            ? `${imageDescription.slice(0, 30)}...`
            : imageDescription}
        </Text>
      </div>

      {error && (
        <span className={styles.errorMsg}>
          <Text s10 lh12>
            {errorMessage ? errorMessage : error}
          </Text>
        </span>
      )}
    </div>
  );
};

export default UploadFile;
