import Input, { inputType } from "../../atoms/Input/Input";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PostContext } from "../../../contexts/PostContext";
import { languages } from "../../../dummyData/dummyData";
import styles from "../Popups/FormPopup/FormPopup.module.scss";
import TextArea from "../../atoms/TextArea/TextArea";
import Select from "../../atoms/Select/Select";
import UploadFile from "../UploadFile/UploadFile";
import IncrementInput from "../../atoms/IncrementInput/IncrementInput";
import CalendarInput from "../../atoms/CalendarInput/CalendarInput";
import { isValidDateString } from "../../../utils/dateHelpers";
import { dateFormat } from "../../../utils/formatters";
import SelectSearch from "../../atoms/SelectSearch/SelectSearch";
import MultiComplete from "../MultiComplete/MultiComplete";
import StarPickerInput from "../../atoms/StarPickerInput/StarPickerInput";
import RadioInputs from "../RadioInputs/RadioInputs";
import SaveBackButtons from "../SaveBackButtons/SaveBackButtons";
import CheckboxInput from "../../atoms/CheckboxInput/CheckboxInput";
import PasswordWithRequirementsInput from "../../atoms/PasswordWithRequirementsInput/PasswordWithRequirementsInput";
import { reportError } from "../../../utils/errorHandling";

export const TYPE_TEXT = inputType.text;
export const TYPE_NUMBER = inputType.number;
export const TYPE_PASSWORD = inputType.password;
export const TYPE_PASSWORD_WITH_REQUIREMENTS = "passwordWithRequirements";
export const TYPE_EMAIL = inputType.email;
export const TYPE_FILE = inputType.file;
export const TYPE_DROPDOWN = "dropdown";
export const TYPE_TEXTAREA = "textarea";
export const TYPE_IMAGE = "image";
export const TYPE_DATE = "date";
export const TYPE_AUTOCOMPLETE = "autocomplete";
export const TYPE_MULTICOMPLETE = "multicomplete";
export const TYPE_STARPICKER = "starpicker";
export const TYPE_CHECKBOX = "checkbox";
export const TYPE_RADIO_INPUTS = "radioInputs";

export const VARIANT_MULTI_INPUTS_COLUMN = "multiInputsColumn";

const parsePreparedFields = (fields, mapCallback) => Object.fromEntries(Object.entries([].concat(...fields
  .map(field => Array.isArray(field?.inputs) ? field.inputs : field)
  .filter(field => field.name !== undefined || Array.isArray(field))
)).map(mapCallback));

const Form = ({ fields, variant, onSave, saveLabel, onBack, backLabel, resetTrigger, autoFocus }) => {
  const wrapperRef = useRef();
  const [preparedFields, setPreparedFields] = useState(typeof fields === 'function' ? fields() : fields);
  const [formData, setFormData] = useState(parsePreparedFields(preparedFields, ([, field]) => [field.name, field.value]));
  const autoFocusFieldIndex = preparedFields.findIndex(field => !field.disabled);

  useEffect(() => {
    if (typeof fields === 'function') {
      setPreparedFields(fields(formData));
    } else {
      setPreparedFields(fields);
    }
  }, [formData, fields]);

  const [errors, setErrors] = useState(parsePreparedFields(preparedFields, ([, field]) => [field.name, undefined]));
  const { isPostFinish, postResponse, isResponseOk } = useContext(PostContext);
  useEffect(() => {
    if (isPostFinish && !isResponseOk && typeof postResponse === 'object') {
      setErrors(Object.fromEntries(Object.entries(postResponse).map(
        ([name, errors]) => [name, errors[0]]
      )));
    }
  }, [postResponse]);

  const validate = () => {
    let result = true;

    setErrors(parsePreparedFields(preparedFields, ([, field]) => {
      const errors = [
        validateRequired(field),
        field.validation ? field.validation(formData) : undefined
      ].filter(result => result !== undefined);

      result = result && errors.length === 0;

      return [field.name, errors.join(" ")];
    }));

    return result;
  };

  const validateRequired = (field) => {
    return field.required && (
      formData[field.name] === undefined ||
      formData[field.name] === '' ||
      (Array.isArray(formData[field.name]) && formData[field.name].length === 0)
    ) ?
      `${field.label} ${languages.EN.messages.isRequired}` :
      undefined;
  }

  useEffect(() => {
    const firstError = Object.entries(errors).find(([, error]) => !!error);

    if (firstError !== undefined) {
      try {
        const element = wrapperRef.current.querySelector(`[name=${firstError[0]}]`);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } catch (error) {
        reportError(error, { componentStack: "Form.js" });
      }

    }
  }, [errors]);

  useEffect(() => {
    if (resetTrigger) {
      setFormData(parsePreparedFields(preparedFields, ([, field]) => [field.name, field.value]));
      setErrors(parsePreparedFields(preparedFields, ([, field]) => [field.name, undefined]));
    }
  }, [resetTrigger]);

  const handleDefault = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSelect = (name, id) => {
    setFormData(prevState => ({ ...prevState, [name]: id }));
  }

  const handleMuliSelect = (name, id, item) => {
    if (item !== "" && (!formData[name] || !formData[name].includes(item))) {
      setFormData(prevState => ({ ...prevState, [name]: formData[name] ? formData[name].concat([id]) : [id] }));
    }
  };

  const handleDeleteMultiSelect = (name, id) => {
    setFormData(prevState => ({ ...prevState, [name]: formData[name].filter((item) => item !== id) }));
  };

  const handleRadioInputs = (inputs) => {
    setFormData(prevState => ({ ...prevState, ...inputs}));
  }

  const handleOnSave = () => validate() ? onSave(Object.fromEntries(Object.entries(formData).map(([key, value]) => [key, value !== null ? value : ""]))) : false;
  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleOnSave();
    }
  };

  let variantStyle = "";

  switch (variant) {
    case VARIANT_MULTI_INPUTS_COLUMN:
      variantStyle = styles.multiInputsColumn;
      break;
  }

  const renderInput = (data, i) => {
    if (Array.isArray(data)) {
      return (
        <div key={i} className={styles.singleLineMultiInputs}>{data.map(field => renderInput(field, i))}</div>
      );
    } else if (data.visible !== false) {
      switch (data.type) {
        case TYPE_TEXTAREA:
          return (
            <TextArea
              key={data.name}
              name={data.name}
              value={formData[data.name]}
              required={data.required}
              disabled={data.disabled}
              handleTextArea={handleDefault}
              placeholder={data.label}
              error={errors[data.name] || postResponse?.[data.name]}
              noMargin={data.noMargin}
              autoFocus={autoFocus && i === autoFocusFieldIndex}
            />
          );
        case TYPE_DROPDOWN:
          return (
            <Select
              key={data.name}
              name={data.name}
              currentName={data.items[formData[data.name]]} // TODO: refactor to use raw "value"
              required={data.required}
              disabled={data.disabled}
              handleSelect={handleSelect}
              placeholder={data.label}
              items={Object.entries(data.items).map(([id, label]) => ({ id: id, label: label }))}
              error={errors[data.name]}
              noMargin={data.noMargin}
            />
          );
        case TYPE_IMAGE:
          return (
            <UploadFile
              key={data.name}
              name={data.name}
              setFormData={setFormData}
              formData={formData}
              placeholder={data.label}
              required={data.required}
              userImg={data.value}
              error={!!errors[data.name]}
              errorMessage={errors[data.name]}
              square={data.square}
              allowedTypes={data.allowedTypes}
            />
          );
        case TYPE_FILE:
          return (
            <UploadFile
              key={data.name}
              name={data.name}
              setFormData={setFormData}
              formData={formData}
              placeholder={data.label}
              required={data.required}
              isFile={true}
              error={!!errors[data.name]}
              errorMessage={errors[data.name]}
              allowedTypes={data.allowedTypes}
            />
          );
        case TYPE_NUMBER:
          return (
            <IncrementInput
              key={data.name}
              name={data.name}
              value={formData[data.name] ?? 0}
              required={data.required}
              disabled={data.disabled}
              handleInput={handleDefault}
              placeholder={data.label}
              error={errors[data.name]}
              autoFocus={autoFocus && i === autoFocusFieldIndex}
            />
          );
        case TYPE_DATE:
          return (
            <CalendarInput
              key={data.name}
              name={data.name}
              value={formData[data.name] && isValidDateString(formData[data.name]) ? dateFormat(formData[data.name]) : formData[data.name] ?? ""}
              required={data.required}
              disabled={data.disabled}
              handleInput={handleDefault}
              placeholder={data.label}
              error={errors[data.name]}
            />
          );
        case TYPE_AUTOCOMPLETE:
          return (
            <SelectSearch
              key={data.name}
              name={data.name}
              currName={data.items.find((item) => item.id === formData[data.name])?.label}
              required={data.required}
              disabled={data.disabled}
              items={data.items}
              withImg={data.items[0]?.photo !== undefined}
              handleSelect={handleSelect}
              placeholder={data.label}
              error={errors[data.name]}
              autoFocus={autoFocus && i === autoFocusFieldIndex}
            />
          );
        case TYPE_MULTICOMPLETE:
          return (
            <MultiComplete
              key={data.name}
              name={data.name}
              value={data.items.filter((item) => (formData[data.name] ?? []).indexOf(item.id) !== -1)}
              required={data.required}
              disabled={data.disabled}
              items={data.items}
              withImg={data.items[0]?.photo !== undefined}
              handleSelect={handleMuliSelect}
              handleDelete={handleDeleteMultiSelect}
              label={data.label}
              selectedItems={data.items.filter((item) => (formData[data.name] ?? []).indexOf(item.id) !== -1)}
              errors={errors[data.name]}
              autoFocus={autoFocus && i === autoFocusFieldIndex}
            />
          );
        case TYPE_STARPICKER:
          return (
            <StarPickerInput
              key={data.name}
              name={data.name}
              value={formData[data.name]}
              required={data.required}
              disabled={data.disabled}
              handleInput={(value) => handleSelect(data.name, value)}
              placeholder={data.label}
              error={errors[data.name]}
            />
          );
        case TYPE_CHECKBOX:
          return (
            <CheckboxInput
              key={data.name}
              name={data.name}
              label={data.descripiton ?? data.label}
              value={formData[data.name]}
              required={data.required}
              error={errors[data.name]}
              handleInput={() => handleDefault({ target: { name: data.name, value: formData[data.name] ? (data.required ? "" : 0) : 1 } })}
              noMargin={data.noMargin}
            />
          );
        case TYPE_RADIO_INPUTS:
          return (
            <RadioInputs
              key={i}
              handleInput={handleRadioInputs}
              placeholder={data.label}
              inputs={data.inputs}
              errors={errors}
            />
          );
        case TYPE_PASSWORD_WITH_REQUIREMENTS:
          return (
            <PasswordWithRequirementsInput
              key={data.name}
              name={data.name}
              value={formData[data.name] ?? ''}
              required={data.required}
              disabled={data.disabled}
              handleInput={handleDefault}
              placeholder={data.label}
              error={errors[data.name]}
              autoFocus={autoFocus && i === autoFocusFieldIndex}
            />
          );
        default:
          return (
            <Input
              key={data.name}
              name={data.name}
              value={formData[data.name] ?? ''}
              required={data.required}
              disabled={data.disabled}
              variant={data.type}
              handleInput={handleDefault}
              placeholder={data.label}
              error={errors[data.name]}
              autoFocus={autoFocus && i === autoFocusFieldIndex}
            />
          );
      }
    } else {
      return "";
    }
  }

  return (
    <>
      <div className={`${styles.inputWrapper} ${variantStyle}`} ref={wrapperRef} onKeyDown={keyDownHandler}>
        {preparedFields.map((field, i) => renderInput(field, i))}
      </div>
      <SaveBackButtons
        onSave={handleOnSave}
        saveLabel={saveLabel}
        onBack={onBack}
        backLabel={backLabel}
      />
    </>
  );
};

export default Form
