import styles from "./NotificationTableHeader.module.scss";
import TableCell from "../../../atoms/table/TableCell/TableCell";
import { languages } from "../../../../dummyData/dummyData";

const NotificationTableHeader = () => {
  return (
    <div className={styles.Wrapper}>
      <TableCell variant='primary'/>
      <TableCell variant='primary'>{languages.EN.labels.webApp.toUpperCase()}</TableCell>
      {/*<TableCell variant='primary'>{languages.EN.labels.mobileApp.toUpperCase()}</TableCell>*/}
      <TableCell variant='primary'>{languages.EN.labels.email.toUpperCase()}</TableCell>
    </div>
  );
};

export default NotificationTableHeader;
