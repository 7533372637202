import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { ACTIONS, PopupsContext } from "../contexts/PopupsContext";
import { languages } from "../dummyData/dummyData";
import { coursesRepository } from "../repository/courses.repository";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "../routes";
import { PostContext } from "../contexts/PostContext";
import Text from "../components/atoms/Text/Text";

export const useCourseButtons = (data) => {
  const { included_in_license, is_enrolled, id } = data;
  const { userData } = useContext(UserContext);
  const { callPopup } = useContext(PopupsContext);
  const { postData } = useContext(PostContext);
  const history = useHistory();

  const hasTokens = userData.available_coins > 0;
  const canStartWatching = included_in_license || hasTokens;
  const getGoToShopReason = is_enrolled ? languages.EN.messages.yourAccessToCourseExpired : (userData.available_coins === 0 ? languages.EN.messages.noMoreTokensAvailable : null);

  const goToCourseView = (id) => {
    history.push(generatePath(Routes.courseView.about.base, { id: id }));
  }

  const goToShop = (id) => {
    window.open(`${process.env.REACT_APP_LANDING_URL}/wp-json/product/pa_course_id/${id}`, '_blank');
  }

  return {
    icon: canStartWatching ? 'play' : 'cart',
    label: canStartWatching ? (is_enrolled ? languages.EN.labels.continueWatching : languages.EN.labels.startCourse) : languages.EN.labels.buyIt,
    onClick: () => {
      if (included_in_license && is_enrolled) {
        goToCourseView(id);
      } else if (included_in_license) {
        coursesRepository
          .sendCourseEnroll(encodeURIComponent(id))
          .then((res) => res.status === 200 && goToCourseView(id));
      } else if (hasTokens) {
        callPopup({
          type: ACTIONS.CONFIRM,
          payload: {
            header: languages.EN.labels.confirmation,
            content: (
              <>
                {languages.EN.messages.areYouSureYouWantToEnroll}<br />
                Tokens left: {userData.available_coins}<br /><br />
                <Text w800 red>{languages.EN.messages.warning}</Text> <Text w600>{languages.EN.messages.thisActionCannotBeUndone}</Text>
              </>
            ),
            action: () => postData(
              () => coursesRepository.sendCourseEnroll(encodeURIComponent(id)),
              () => {
                callPopup({ type: ACTIONS.REMOVE });
                goToCourseView(id);
              }),
          },
        });
      } else if (getGoToShopReason === null) {
        goToShop(id);
      } else {
        callPopup({
          type: ACTIONS.INFORMATION,
          payload: {
            header: languages.EN.labels.information,
            content: (
              <>
                {getGoToShopReason}<br /><br />
                {languages.EN.messages.pleaseGoToShop}
              </>
            ),
            action: () => goToShop(id),
            buttonLabel: languages.EN.labels.goToShop
          },
        });
      }
    },
  };
};