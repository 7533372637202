import Text from "../../atoms/Text/Text";
import styles from "./Review.module.scss";
import starEmpty from "../../../assets/icons/statistics/starEmpty.svg";
import starFilled from "../../../assets/icons/statistics/starFilled.svg";
import { languages } from "../../../dummyData/dummyData";
import { dateTimeFormat } from "../../../utils/formatters";
import RoundImage from "../../atoms/RoundImage/RoundImage";

const Review = ({ componentProps }) => {
  const { user, created, rating, review } = componentProps;

  const createRating = () => {
    const ratingArry = [];
    const emptyStar = 5 - rating;
    for (let i = rating; i > 0; i--) {
      ratingArry.push(true);
    }
    for (let i = emptyStar; i > 0; i--) {
      ratingArry.push(false);
    }
    return ratingArry;
  };
  var ratingArray = createRating();

  return (
    <div className={styles.mainContent}>
      <RoundImage image={user.profile.profile_image_small} size={70} />
      <div className={styles.textContent}>
        <Text s12 w700 lh24 dark-6>
          {user.profile.name && user.profile.surname
            ? user.profile.name + " " + user.profile.surname
            : languages.EN.placeholders.empty}
        </Text>
        <Text s12 w400 lh24 dark-6>
          {review ? review : languages.EN.placeholders.empty}
        </Text>
        <Text s12 w400 lh24 dark-6>
          {dateTimeFormat(created)}
        </Text>
        <div className={styles.rating}>
          {ratingArray.map((value, i) =>
            value ? (
              <img key={i} src={starFilled} alt="" />
            ) : (
              <img key={i} src={starEmpty} alt="" />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Review;
